
<template>
    <recess-card variant="variant1" class="my-3 px-5">
        <div class="row">
            <!-- Subtitle -->
            <div class="col-12">
                <subtitle-text
                    :subtitle="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.Title"
                    qa-class="qa-edit-general-info"
                />
            </div>
        </div>

        <div class="row">
            <!-- Shared learning method form -->
            <div class="col-6">
                <h5>
                    {{ PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethodTitle }}
                </h5>

                <div>
                    <recess-multi-select
                        :selected-options="planningAndInvoicesData?.learningMethod?.format ?? null"
                        :options="learningMethodFormats"
                        :search-input="true"
                        :single-select="true"
                        :label-text="`${PROVIDER.Portfolio.CourseCreate.Form.LearningMethod.Format.Label}*`"
                        :placeholder="PROVIDER.Portfolio.CourseCreate.Form.LearningMethod.Format.SelectText"
                        :error-message="learningMethodFormatError"
                        class="qa-learning-method-format"
                        @input="(newValue) => setLearningMethodProperties(newValue)"
                    />
                </div>
            </div>

            <!-- Shared general info form -->
            <div class="col-12 mt-4">
                <h5>{{ PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.GeneralInfoTitle }}</h5>

                <div>
                    <div class="row">
                        <div class="col-6">
                            <recess-input
                                v-model="generalInfoData.name"
                                :label-text="`${PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.Name}*`"
                                :error-message="courseNameError"
                                class="qa-course-name"
                                @blur="$v.generalInfoData.name.$touch()"
                            />
                        </div>

                        <div class="col-4">
                            <recess-input
                                v-model="generalInfoData.externalId"
                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.ExternalId"
                                :error-message="courseExternalIdError"
                                class="qa-course-id"
                                @blur="$v.generalInfoData.externalId.$touch()"
                            >
                                <template slot="customIcon">
                                    <recess-tooltip>
                                        <template slot="content">
                                            <div>{{ PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.ExternalIdIconText }}</div>
                                        </template>
                                    </recess-tooltip>
                                </template>
                            </recess-input>
                        </div>
                    </div>

                    <div :class="['row', (isOnCourseEditPage && generalInfoData.productType === 'Regular') ? 'mb-0 mt-4' : 'my-4']">
                        <div class="col-6">
                            <div v-if="isOnCourseEditPage"
                                class="d-flex"
                            >
                                <h5 class="align-self-center">{{ PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.Type }}</h5>
                                <p class="mb-2 ml-3 align-self-center qa-selected-product-type">{{ chosenProductType }}</p>
                            </div>
                            <recess-radio-buttons v-if="isOnCourseCreatePage || isOnCourseDuplicatePage">
                                <recess-radio-input
                                    v-for="productType in productTypes"
                                    :key="productType.id"
                                    name="productTypesRadioOptions"
                                    styling-variant="secondary"
                                    :class="`mr-5 mb-4 qa-product-type-${productType.value}`"
                                    :label-text="productType.displayText"
                                    :default-checked="checkProductType(productType)"
                                    :value="productType.value"
                                    @input="(newValue) => updateProductType(newValue)"
                                />
                            </recess-radio-buttons>

                            <div class="mb-4">
                                <router-link to="/support-center/incompany" class="c-navigation__link qa-nav-support-center">
                                    {{ PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.IncompanyInformation.Part1 }}
                                </router-link>

                                {{ PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.IncompanyInformation.Part2 }}
                            </div>

                            <recess-multi-select
                                v-if="(isOnCourseEditPage && !disableAccountSelectionForCourse) || isOnCourseCreatePage || isOnCourseDuplicatePage"
                                v-show="displayProductTargetAccount"
                                :selected-options="selectedAccounts"
                                :options="accountOptions"
                                :search-input="true"
                                :enable-preview-selected-items="true"
                                :disabled="disableAccountSelectionForCourse"
                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.SelectAccount.Label"
                                :placeholder="PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.SelectAccount.Placeholder"
                                class="qa-price-agreement-provider-reference"
                                :error-message="productTypeAccountError"
                                @input="(newValue) => setProductTargetAccounts(newValue)"
                            />
                        </div>
                        <div class="col-6">
                            <div class="d-flex">
                                <span class="d-flex">
                                    {{ PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.IsProfessionalCourse.Label }}

                                    <recess-tooltip class="ml-1">
                                        <template slot="content">
                                            <div>
                                                {{ PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.IsProfessionalCourse.Tooltip.TopLine }} <br />
                                                {{ PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.IsProfessionalCourse.Tooltip.BottomLine }}
                                            </div>
                                        </template>
                                    </recess-tooltip>
                                </span>

                                <recess-toggle-input
                                    v-model="generalInfoData.isProfessionalCourse"
                                    name="isProfessionalCourse"
                                    class="qa-professional-course ml-2"
                                    size="small"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Edit only general info form -->
            <div class="col-12">
                <div class="row">
                    <div class="col-6">
                        <recess-input
                            v-model="generalInfoData.shortName"
                            :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.ExtraOptions.CourseShortName.Label"
                            class="qa-course-short-name"
                        />
                    </div>
                    <div class="col-4">
                        <recess-input
                            v-model="generalInfoData.source"
                            :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.ExtraOptions.CourseSource.Label"
                            class="qa-course-source"
                            disabled
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                    </div>
                    <div class="col-4">
                        <recess-input
                            v-model="generalInfoData.edudexProgramUrl"
                            :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.ExtraOptions.EdudexProgramUrl.Label"
                            class="qa-course-edudex-program-url"
                            disabled
                        />
                    </div>
                </div>

                <!-- startDate, endDate of the course-->
                <div class="row">
                    <div class="col-12">
                        <h5 class="mb-4">
                            {{ PROVIDER.Portfolio.CourseCreate.Form.StartDateAndEndDate.Title }}
                        </h5>
                        <div class="d-flex">
                            <recess-date-picker
                                v-model="generalInfoData.startDate"
                                date-picker-input-custom-width="170px"
                                :default-value="generalInfoData.startDate"
                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.StartDateAndEndDate.StartDate"
                                class="qa-course-start-date"
                                :error-message="courseStartDateError"
                                @input="(newValue) => onCourseStartDateChanged(newValue)"
                                @blur="$v.generalInfoData.startDate.$touch()"
                            />
                            <recess-date-picker
                                v-model="generalInfoData.endDate"
                                date-picker-input-custom-width="170px"
                                :default-value="generalInfoData.endDate"
                                :label-text="PROVIDER.Portfolio.CourseCreate.Form.StartDateAndEndDate.EndDate"
                                class="ml-3 qa-course-end-date"
                                data-test="course-end-date"
                                :error-message="courseEndDateError"
                                @input="(newValue) => onCourseEndDateChanged(newValue)"
                            />
                        </div>
                    </div>
                </div>


                <div class="row mt-4 pt-1">
                    <div class="col-12">
                        <h5 class="mb-4">
                            {{ PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.Title }}
                        </h5>
                    </div>
                    <div class="col-3">
                        <recess-multi-select
                            :selected-options="generalInfoData?.learningMethod?.languageCode?.toLowerCase()"
                            :options="languages"
                            :single-select="true"
                            :search-input="true"
                            :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.Language.Label"
                            class="qa-learning-method-language"
                            @input="(newValue) => setLanguages(newValue)"
                        />
                    </div>
                    <div class="col-3">
                        <recess-multi-select
                            :selected-options="generalInfoData?.learningMethod?.languageMaterialCode?.toLowerCase()"
                            :options="languages"
                            :single-select="true"
                            :search-input="true"
                            :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.LanguageMaterial.Label"
                            class="qa-learning-method-language-material"
                            @input="(newValue) => setLearningMethodLanguageMaterial(newValue)"
                        />
                    </div>
                </div>

                <!-- studyLoad value, studyLoad unit-->
                <div class="row mt-5">
                    <div class="col-12">
                        <h5 class="mb-4">
                            {{ PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.DurationTitle }}
                        </h5>
                    </div>
                    <div class="col-3">
                        <recess-numeric-input
                            v-if="planningAndInvoicesData && planningAndInvoicesData.learningMethod && planningAndInvoicesData.learningMethod.studyLoad"
                            :currency-prop="null"
                            :precision-prop="1"
                            :value="planningAndInvoicesData.learningMethod.studyLoad.value"
                            :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.StudyLoadValue.Label"
                            :error-message="studyLoadValueError"
                            class="qa-study-load-value"
                            @input="(value) => $emit('setStartMoments', { action: 'setStudyloadValue', value })"
                        >
                            <template slot="customIcon">
                                <recess-tooltip>
                                    <template slot="content">
                                        <div>{{ PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.StudyLoadValue.Tooltip }} </div>
                                    </template>
                                </recess-tooltip>
                            </template>
                        </recess-numeric-input>

                    </div>
                    <div class="col-3">
                        <recess-multi-select
                            v-if="planningAndInvoicesData && planningAndInvoicesData.learningMethod && planningAndInvoicesData.learningMethod.studyLoad"
                            :selected-options="planningAndInvoicesData.learningMethod.studyLoad.unit"
                            :options="studyLoadPeriods"
                            :single-select="true"
                            :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.StudyLoadUnit.Label"
                            class="qa-study-load-unit"
                            :disabled="!planningAndInvoicesData.learningMethod.studyLoad.value"
                            :error-message="studyLoadUnitError"
                            @input="(value) => $emit('setStartMoments', { action: 'setStudyloadUnit', value })"
                        />
                    </div>
                </div>

                <!-- contactSessions, duration value, duration unit -->
                <div class="row">
                    <recess-numeric-input
                        v-if="planningAndInvoicesData && planningAndInvoicesData.learningMethod && planningAndInvoicesData.learningMethod.duration"
                        :value="planningAndInvoicesData.learningMethod.duration.value"
                        :currency-prop="null"
                        :precision-prop="1"
                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.LearningMethod.DurationValue.Label"
                        class="col-3 qa-course-duration-value"
                        :error-message="durationValueError"
                        @input="(value) => $emit('setStartMoments', { action: 'setDurationValue', value })"
                    >

                        <template slot="customIcon">
                            <recess-tooltip>
                                <template slot="content">
                                    <div>{{ PROVIDER.Portfolio.CourseCreate.Form.LearningMethod.DurationValue.Tooltip }}</div>
                                </template>
                            </recess-tooltip>
                        </template>
                    </recess-numeric-input>

                    <recess-multi-select
                        v-if="planningAndInvoicesData && planningAndInvoicesData.learningMethod && planningAndInvoicesData.learningMethod.duration"
                        :selected-options="planningAndInvoicesData.learningMethod.duration.unit"
                        :options="durationUnits"
                        :single-select="true"
                        :label-text="PROVIDER.Portfolio.CourseCreate.Form.LearningMethod.DurationUnit.Label"
                        class="col-3 qa-course-duration-unit"
                        :disabled="!planningAndInvoicesData.learningMethod.duration.value"
                        :error-message="durationUnitError"
                        @input="(value) => $emit('setStartMoments', { action: 'setDurationUnit', value })"
                    />
                </div>
                <div class="row">
                    <recess-input
                        v-if="planningAndInvoicesData && planningAndInvoicesData.learningMethod"
                        :value="planningAndInvoicesData.learningMethod.contactSessions"
                        :label-text="PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.ContactSessions.Label"
                        :error-message="contactSessionsError"
                        class="col-3 qa-course-contact-sessions"
                        @input="(value) => $emit('setStartMoments', { action: 'setContactSessions', value })"
                    />
                </div>
            </div>

            <div class="col-12">
                <recess-divider show-line />
            </div>

            <div class="col-12 mt-3 d-flex justify-content-end">
                <recess-button
                    variant="secondary"
                    :title="!isOnCourseEditPage ? BUTTON_TEXT.nextTab : BUTTON_TEXT.save"
                    class="qa-edit-course-submit-button"
                    @click.prevent.native="tabButtonAction()"
                />
            </div>
        </div>
    </recess-card>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'

import { validationMixin } from 'vuelidate'
import { required, requiredIf, minValue, integer } from 'vuelidate/lib/validators'

import { getItems, getEnumList, deleteItem, postItem } from '@/../../shared/api/SharedClient'
import tabMixin from '../../../mixins/tabNavigationMixin'

import {
    isValidPositiveDecimalNumberIncludingZero,
    isEndDateBiggerThanStartDate,
    isValidDate,
    isValidDateFormat,
    isDateInTheFuture
} from '@/utils/validationHelpers'

import {
    isRequiredErrorMessage, isValidIntegerErrorMessage, requiredError, isInvalidErrorMessage,
    dateRangeHasErrorMessage, isDateInThePast, positiveDecimalNumber, negativeNumber, hasError
} from '@/../../shared/constants/validationMessageHelper.js'

const SubtitleText = () => import('@/components/atoms/SubtitleText')
const StaticAlert = () => import('@/components/atoms/StaticAlert')

export default {
    components: {
        SubtitleText,
        StaticAlert
    },
    mixins: [validationMixin, tabMixin],
    inject: ['generalInfoCourseData'],
    props: {
        planningAndInvoicesData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    validations: {
        planningAndInvoicesData: {
            learningMethod: {
                format: { required },
                contactSessions: { minValue: minValue(0), integer },
                studyLoad: {
                    value: { isValidPositiveDecimalNumberIncludingZero },
                    unit: {
                        // eslint-disable-next-line func-names
                        required: requiredIf(function () {
                            return this.planningAndInvoicesData?.learningMethod?.studyLoad?.value
                        })
                    }
                },
                duration: {
                    unit: {
                        required: requiredIf(function () {
                            return this.planningAndInvoicesData?.learningMethod?.duration?.value
                        })
                    },
                    value: {
                        required,
                        graterThanZero(value) {
                            if (value !== null) {
                                return value > 0
                            }

                            return true
                        }
                    }
                }
            }
        },
        generalInfoData: {
            name: { required },
            externalId: { required },
            startDate: {
                isValidDate,
                isValidDateFormat,
                isValidEndDate() {
                    return isEndDateBiggerThanStartDate(this.generalInfoData.startDate, this.generalInfoData.endDate)
                }
            },
            endDate: {
                isValidDate,
                isValidDateFormat,
                isValidEndDate() {
                    return isEndDateBiggerThanStartDate(this.generalInfoData.startDate, this.generalInfoData.endDate)
                },
                isInTheFuture(dateString) {
                    return isDateInTheFuture(dateString)
                }
            }
        },
        selectedAccounts: {
            required: requiredIf(function checkIfRequiered() {
                return !this.disableAccountSelectionForCourse
            })
        }
    },
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            generalInfoData: {
                id: null,
                name: '',
                externalId: '',
                isProfessionalCourse: false,
                productType: null,
                shortName: null,
                source: null,
                edudexProgramUrl: null,
                startDate: null,
                endDate: null,
                learningMethod: {
                    language: null,
                    languageCode: null,
                    languageMaterialCode: null,
                    format: null
                }
            },
            studyLoadPeriods: [],
            learningMethodFormats: [],
            productTypes: [],
            chosenProductType: '',
            accountOptions: [],
            selectedAccounts: [],
            originalSelectedAccounts: [],
            originalSelectedProductTargetAccounts: [],
            durationUnitsPlural: [],
            durationUnitsSingular: [],
            languages: [],
            displayProductTargetAccount: false
        }
    },
    computed: {
        durationUnits() {
            if (this.planningAndInvoicesData.learningMethod.duration.value === null || this.planningAndInvoicesData.learningMethod.duration.value === 0) {
                return null
            }

            if (this.planningAndInvoicesData.learningMethod.duration.value > 1) {
                return this.durationUnitsPlural
            }

            return this.durationUnitsSingular
        },
        durationValueError() {
            return (isRequiredErrorMessage('duration', 'Doorlooptijd', this.$v.planningAndInvoicesData.learningMethod.duration.value)
                || hasError(negativeNumber('Doorlooptijd'), this.$v.planningAndInvoicesData.learningMethod.duration.value, 'graterThanZero'))
        },
        durationUnitError() {
            if (this.$v.planningAndInvoicesData.learningMethod.duration.unit.$invalid && !this.$v.planningAndInvoicesData.learningMethod.duration.unit.required) {
                return requiredError('Doorlooptijd eenheid')
            }

            return null
        },
        studyLoadValueError() {
            return this.$v.planningAndInvoicesData.learningMethod.studyLoad.value.$invalid &&
                !this.$v.planningAndInvoicesData.learningMethod.studyLoad.value.isValidPositiveDecimalNumberIncludingZero
                ? positiveDecimalNumber
                : null
        },
        studyLoadUnitError() {
            return this.$v.planningAndInvoicesData.learningMethod.studyLoad.unit.$invalid &&
                !this.$v.planningAndInvoicesData.learningMethod.studyLoad.unit.required
                ? requiredError('Studielast eenheid')
                : null
        },
        contactSessionsError() {
            return (
                isValidIntegerErrorMessage(PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.ContactSessions.Label, this.$v.planningAndInvoicesData.learningMethod.contactSessions, 'minValue') ||
                isValidIntegerErrorMessage(PROVIDER.Portfolio.CourseEdit.GeneralInfoTab.LearningMethod.ContactSessions.Label, this.$v.planningAndInvoicesData.learningMethod.contactSessions, 'integer')
            )
        },
        isManualOrApiCourse() {
            return this.planningAndInvoicesData.source === 'Manual' || this.generalInfoData.source === 'API'
        },
        learningMethodFormatError() {
            return isRequiredErrorMessage('learningMethod', PROVIDER.Portfolio.CourseCreate.Form.LearningMethod.Format.Label, this.$v.planningAndInvoicesData.learningMethod.format)
        },
        courseNameError() {
            return isRequiredErrorMessage('name', PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.Name, this.$v.generalInfoData.name)
        },
        productTypeAccountError() {
            return !this.$v.selectedAccounts.required ? requiredError('Klant(en)') : null
        },
        courseExternalIdError() {
            return isRequiredErrorMessage('externalId', 'Identificatiecode', this.$v.generalInfoData.externalId)
        },
        disableAccountSelectionForCourse() {
            return this.generalInfoData.productType === 'Regular'
        },
        courseStartDateError() {
            return isInvalidErrorMessage(PROVIDER.Portfolio.CourseCreate.Form.StartDateAndEndDate.StartDate, this.$v.generalInfoData.startDate, 'isValidDate')
        },
        courseEndDateError() {
            return (
                isInvalidErrorMessage(PROVIDER.Portfolio.CourseCreate.Form.StartDateAndEndDate.EndDate, this.$v.generalInfoData.endDate, 'isValidDate') ||
                dateRangeHasErrorMessage(
                    'maxDate',
                    this.$v.generalInfoData.endDate,
                    'isValidEndDate',
                    { startDate: 'startdatum', endDate: 'actief tot' }
                ) ||
                isDateInThePast(this.$v.generalInfoData.endDate, 'isInTheFuture')
            )
        }
    },
    watch: {
        disableAccountSelectionForCourse: {
            handler() {
                if (this.disableAccountSelectionForCourse) {
                    this.selectedAccounts = []
                    this.originalSelectedAccounts = []
                    this.originalSelectedProductTargetAccounts = []
                }
            }
        },
        generalInfoCourseData: {
            handler() {
                if (Object.entries(this.generalInfoCourseData.generalInfoData).length !== 0 && this.generalInfoCourseData.generalInfoData.constructor === Object) {

                    this.generalInfoData = JSON.parse(JSON.stringify(this.generalInfoCourseData.generalInfoData))

                    if (this.generalInfoData.learningMethod.language) {
                        this.generalInfoData.learningMethod.language = this.generalInfoData.learningMethod.language.toLowerCase()
                    }


                    this.getData()
                }
            },
            immediate: true,
            deep: true
        },
        generalInfoData: {
            handler() {
                this.triggerProgressChange()
            },
            deep: true
        }
    },
    mounted() {
        this.$bus.on('triggerProductTargetAccounts', this.addOrRemoveProductTargetAccounts)
    },
    beforeDestroy() {
        this.$bus.off('triggerProductTargetAccounts')
    },
    methods: {
        setProductTargetAccounts(newValue) {
            this.selectedAccounts = newValue
        },
        updateProductType(value) {
            this.generalInfoData.productType = value
            this.triggerProgressChange()
        },
        triggerProgressChange() {
            this.$emit('triggerProgressChange', this.generalInfoData)
        },
        setProductType(newValue) {
            this.generalInfoData.productType = newValue
        },
        setLanguages(newValue) {
            this.generalInfoData.learningMethod.languageCode = newValue
        },
        setLearningMethodLanguageMaterial(newValue) {
            this.generalInfoData.learningMethod.languageMaterialCode = newValue
        },
        getData() {
            this.getLearningMethodFormats()
            this.getAccounts()
            this.getProductTargetAccounts()
            this.getTypeOfProduct()
            this.getStudyLoadPeriods()
            this.getDurationUnitsPlural()
            this.getDurationUnitsSingular()
            this.getLanguages()
        },
        updateEditedCourse() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.$emit('submitForm')
            } else {
                this.emitValidation()
            }
        },
        async getProductTargetAccounts() {
            if (this.isOnCourseDuplicatePage) {
                this.selectedAccounts = this.generalInfoData.selectedAccounts
                return
            }

            try {
                const response = await getItems(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.productTargetAccounts,
                    1,
                    99999,
                    null,
                    `productReference eq 'course:${this.generalInfoData.id}'`,
                    false
                )
                if (!response) return

                this.selectedAccounts = response.items.map((selectedAccount) => selectedAccount.accountReference.split(':')[1])
                this.originalSelectedAccounts = JSON.parse(JSON.stringify(this.selectedAccounts))
                this.originalSelectedProductTargetAccounts = response.items
            } catch (error) {
                console.error('Something went wrong while retrieving the product target account list', error)
            }
        },
        async addOrRemoveProductTargetAccounts(payload) {
            let courseId = payload || this.generalInfoData.id
            if (!courseId && !this.disableAccountSelectionForCourse) return

            const promises = []
            let selectProductTargetsToRemove = []
            let selectProductTargetsToAdd = this.selectedAccounts

            if (courseId === this.$route.params.id) {
                selectProductTargetsToRemove = this.originalSelectedProductTargetAccounts.filter((originalSelectedAccount) => {
                    return !this.selectedAccounts.includes(originalSelectedAccount.accountReference.split(':')[1])
                })

                selectProductTargetsToAdd = this.selectedAccounts.filter((originalSelectedAccount) => {
                    return !this.originalSelectedAccounts.includes(originalSelectedAccount)
                })
            }

            const productToBeAdded = selectProductTargetsToAdd?.map((selectedAccount) => {
                return {
                    productReference: `course:${courseId}`,
                    accountReference: `account:${selectedAccount}`
                }
            })

            if (productToBeAdded && productToBeAdded.length > 0) {
                promises.push(this.addProductTargetAccount(productToBeAdded))
            }

            if (selectProductTargetsToRemove && selectProductTargetsToRemove.length > 0) {
                selectProductTargetsToRemove.forEach((element) => {
                    promises.push(this.deleteProductTargetAccount(element.id))
                })
            }

            await Promise.all(promises)

            this.getAccounts()
            this.getProductTargetAccounts()
        },

        async addProductTargetAccount(productToBeAdded) {
            try {
                await postItem(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.productTargetAccounts, productToBeAdded, false)

            } catch (error) {
                this.getNotification('error', PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.ErrorMessages.ProductTargetAccount)
            }
        },

        async deleteProductTargetAccount(productTargetId) {
            try {
                await deleteItem(process.env.VUE_APP_PROVIDER_API_URL, `${API_CALL_URL_PATHS.productTargetAccounts}/${productTargetId}`, false)
            } catch (error) {
                this.getNotification('error', PROVIDER.Portfolio.CourseCreate.Form.GeneralInfo.ErrorMessages.ProductTargetAccount)
            }
        },
        getNotification(type, message) {
            const toastNotification = {
                type,
                message
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })
        },
        async getStudyLoadPeriods() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'studyLoadPeriod',
                    null,
                    null,
                    false
                )
                if (!response) return
                this.studyLoadPeriods = response
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving study load period enum list',
                    error
                )
            }
        },
        async getLearningMethodFormats(format) {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'learningMethodFormat',
                    format,
                    false
                )
                if (!response) return

                if (this.$route.name === 'course-algemeen') {
                    this.learningMethodFormats = response.reduce(
                        (accumulator, learningMethodFormat) => {
                            if (
                                !learningMethodFormat.value.includes('Blended_Blended') &&
                                (learningMethodFormat.value === 'Planned_Coaching' ||
                                    learningMethodFormat.value.includes('Blended_'))
                            ) {
                                learningMethodFormat.disabled = true
                            }

                            return [...accumulator, learningMethodFormat]
                        },
                        []
                    )
                } else {
                    this.learningMethodFormats = response.reduce(
                        (accumulator, learningMethodFormat) => {
                            if (
                                learningMethodFormat.value.includes('Blended_Blended') ||
                                (learningMethodFormat.value !== 'Planned_Coaching' &&
                                    !learningMethodFormat.value.includes('Blended_'))
                            ) {
                                return [...accumulator, learningMethodFormat]
                            }

                            return accumulator
                        },
                        []
                    )
                }
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving learning method format enum list',
                    error
                )
            }
        },
        setLearningMethodProperties(learningMethodFormat) {

            this.$emit('setStartMoments', { action: 'setLearningMethodField', key: 'format', value: learningMethodFormat })

            const learningMethodType = learningMethodFormat.split('_')[0]
            this.$emit('setStartMoments', { action: 'setLearningMethodField', key: 'type', value: learningMethodType })
        },
        async getTypeOfProduct() {
            try {
                const response = await getEnumList(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.enumerations, 'ProductType', null, null, false)
                if (!response) return

                this.productTypes = response

                if (!this.isOnCourseCreatePage) {
                    const getSelectedType = this.productTypes.find(type => type.value === this.generalInfoData.productType)
                    this.chosenProductType = getSelectedType.displayText
                }

                // Type custom/matwerk has been deprecated
                this.productTypes = response.filter(type => type.value !== 'Custom')
            } catch (error) {
                console.error('Something went wrong while retrieving productTypes enum list', error)
            }
        },
        async getLanguages() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'language',
                    null,
                    false
                )

                if (!response) return

                this.languages = [
                    {
                        displayText: 'Selecteer een waarde',
                        value: ''
                    }
                ]
                let languageToLowerCase = null

                response.forEach((language) => {
                    this.languages.push({
                        displayText: language.displayText,
                        value: language.value.toLowerCase()
                    })
                })

                if (this.generalInfoData.learningMethod.language) {
                    languageToLowerCase = this.generalInfoData.learningMethod.language.toLowerCase()
                }

                if (languageToLowerCase !== null && !this.languages.some(language => language.value === languageToLowerCase)) {
                    this.languages.push({
                        displayText: this.generalInfoData.learningMethod.languageDisplayValue,
                        value: languageToLowerCase
                    })
                }

            } catch (error) {
                console.error('Something went wrong while retrieving Language list', error)
            }
        },
        checkProductType(productType) {
            if (productType.value === this.generalInfoData.productType) {
                return productType.value === this.generalInfoData.productType
            }

            return productType.value === 'Regular'
        },
        async getAccounts() {
            try {
                const response = await getItems(process.env.VUE_APP_ACCOUNT_API_URL, API_CALL_URL_PATHS.accounts, 1, 99999, null, 'deleted eq false', false)
                if (!response) return

                this.displayProductTargetAccount = true
                this.accountOptions = response.items.map((account) => {
                    return { displayText: account.name, value: account.id }
                })
            } catch (error) {
                console.error('Something went wrong while retrieving the account list', error)
            }
        },
        async getDurationUnitsPlural() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'durationUnit',
                    null,
                    null,
                    false
                )

                if (!response) return
                this.durationUnitsPlural = response
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving duration unit enum list',
                    error
                )
            }
        },
        async getDurationUnitsSingular() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'durationUnitSingular',
                    null,
                    null,
                    false
                )

                if (!response) return
                this.durationUnitsSingular = response
            } catch (error) {
                console.error(
                    'Something went wrong while retrieving duration unit enum list',
                    error
                )
            }
        },
        onCourseStartDateChanged() {
            this.$v.generalInfoData.startDate.$touch()
            this.$v.generalInfoData.endDate.$touch()
        },
        onCourseEndDateChanged() {
            this.$v.generalInfoData.endDate.$touch()
        }
    }
}
</script>